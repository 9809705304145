import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×8\\@75%1RM`}</p>
    <p>{`Glute Ham Raise 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`5-Full Cleans (185/125)`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`4-Full Cleans`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`3-Full Cleans`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`2-Full Cleans`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`1-Full Clean`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Friday’s 6pm class is cancelled so that we can set up for the
Granite Games Throwdown on Saturday.  If you can come out and help us
set up at 6pm we’d really appreciate it!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday is our Granite Games Spring Throwdown!  If you aren’t
competing come out and cheer on the teams!  First workout starts at
9:00am!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      